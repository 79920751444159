/** @jsx jsx */
import React from 'react'
import { Styled, jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Zoom } from 'react-slideshow-image'
//
import Layout from '../components/layout'
import Seo from '../components/seo'
import { shuffleArray } from '../utils/common'

const MemoizedSwiper = React.memo(({ slides, params }) => <Zoom {...params}>{slides}</Zoom>)

const Homepage = ({ data, location }) => {
  const { contentfulSettings, site } = data

  const [slides, setSlides] = React.useState([])
  const [loaded, setLoaded] = React.useState(false)
  const [headerStyle, setHeaderStyle] = React.useState('White')

  const siteTitle = site?.siteMetadata?.title
  const siteDescription = site?.siteMetadata?.description

  React.useEffect(() => {
    const homepageSlides = contentfulSettings?.homepageWorks || []
    if (!loaded) {
      const shuffled = shuffleArray(homepageSlides)
      setSlides(shuffled)
      setHeaderStyle(shuffled[0].headerStyle)
      setLoaded(true)
    }
  }, [contentfulSettings, loaded])

  const zoomInProperties = {
    indicators: false,
    scale: 1.4,
    arrows: false,
    pauseOnHover: false,
    onChange: idx => {
      const nextSlide = slides[idx + 1] || slides[0]
      setHeaderStyle(nextSlide.headerStyle)
    }
  }

  const bgSlides = slides?.map((slide, idx) => (
    <Styled.div
      key={slide.id}
      className={slide.headerStyle}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        backgroundPosition: 'center center',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
        overflow: 'hidden'
      }}
    >
      <BackgroundImage Tag="section" fluid={slide.image.fluid} backgroundColor={`#040e18`} alt={slide.title}>
        <div style={{ height: '100vh' }}></div>
      </BackgroundImage>
    </Styled.div>
  ))

  return (
    <Layout location={location} headerStyle={headerStyle}>
      <Seo title={siteTitle} description={siteDescription} cardType="summary" />
      <Styled.div sx={{ zIndex: -1, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflow: 'hidden' }}>
        <MemoizedSwiper slides={bgSlides} params={zoomInProperties}>
          {bgSlides}
        </MemoizedSwiper>
      </Styled.div>
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulSettings {
      homepageWorks {
        id
        title
        headerStyle
        image {
          ...FragmentIllustrationAsset
          fluid(maxWidth: 1920, quality: 80, resizingBehavior: FILL) {
            ...FragmentImageFluidNoSVG
          }
        }
      }
    }
  }
`
